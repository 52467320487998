<template>
  <div>
    <location />
    <div class="page">
      <div class="wrap-1200">
        <div
          class="d-flex justify-content-center align-items-center mt-3 mt-lg-4 mb-2"
        >
          <button class="btn p-0" @click="prev()">
            <i class="material-icons fs-1">keyboard_arrow_left</i>
          </button>
          <h5 class="mx-2 text-center">
            {{ searchData.searchdate }} {{ $t("text.common.season") }}
          </h5>
          <button class="btn p-0" @click="next()">
            <i class="material-icons fs-1">keyboard_arrow_right</i>
          </button>
        </div>
        <div class="row g-5 mb-4">
          <div class="col-6">
            <h5 class="subtitle">{{ $t("title.common.avg_score") }}</h5>
            <h6
              class="align-right more"
              @click="
                $router
                  .push({
                    name: 'Stats',
                    query: {
                      rank_field: 'avg_score',
                    },
                  })
                  .catch(() => {})
              "
            >
              Complete leaders
            </h6>
            <table class="table board">
              <thead>
                <tr>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.ranking") }}
                  </th>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.nickname") }}
                  </th>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.favorite_location") }}
                  </th>
                  <th class="col-auto d-sm-table-cell">
                    {{ $t("title.common.score") }}
                  </th>
                </tr>
              </thead>

              <tbody v-if="summary.top_avg_score_list.length > 0">
                <template v-for="(item, index) in summary.top_avg_score_list">
                  <tr :key="index">
                    <td class="col-auto d-lg-table-cell lh-1">
                      <strong class="text-black">{{ item.ranking }}</strong
                      ><br />
                    </td>
                    <td class="col-auto d-lg-table-cell text-start">
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :member_img="item.member_img"
                        :badge_img="item.badge_img"
                      />
                    </td>
                    <td class="col-auto d-lg-table-cell shop-name">
                      {{ item.shopname || "-" }}
                    </td>
                    <td class="col-auto d-sm-table-cell text-point-op">
                      {{ item.rank_field | toFixed }}
                    </td>
                  </tr>
                </template>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="5" class="text-center">
                    {{ $t("text.common.no_data") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6">
            <h5 class="subtitle">{{ $t("title.common.gir") }}</h5>
            <h6 class="align-right">Complete leaders</h6>
            <table class="table board">
              <thead>
                <tr>
                  <th class="col-auto col-lg-1 d-lg-table-cell">
                    {{ $t("title.common.ranking") }}
                  </th>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.nickname") }}
                  </th>
                  <th class="col-auto d-none d-lg-table-cell">
                    {{ $t("title.common.favorite_location") }}
                  </th>
                  <th class="col-auto d-none d-sm-table-cell">%</th>
                </tr>
              </thead>

              <tbody v-if="summary.top_greens_list.length > 0">
                <template v-for="(item, index) in summary.top_greens_list">
                  <tr :key="index">
                    <td class="col-auto d-lg-table-cell lh-1">
                      <strong class="text-black">{{ item.ranking }}</strong
                      ><br />
                    </td>
                    <td class="col-auto d-lg-table-cell text-start">
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :member_img="item.member_img"
                        :badge_img="item.badge_img"
                      />
                    </td>
                    <td class="col-auto d-lg-table-cell shop-name">
                      {{ item.shopname || "-" }}
                    </td>
                    <td class="col-auto d-sm-table-cell text-point-op">
                      {{ item.rank_field | toFixed | suffix("%") }}
                    </td>
                  </tr>
                </template>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="5" class="text-center">
                    {{ $t("text.common.no_data") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row g-5 mb-4">
          <div class="col-6">
            <h5 class="subtitle">{{ $t("title.common.avg_distance") }}</h5>
            <h6 class="align-right">Complete leaders</h6>
            <table class="table board">
              <thead>
                <tr>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.ranking") }}
                  </th>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.nickname") }}
                  </th>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.favorite_location") }}
                  </th>
                  <th class="col-auto d-sm-table-cell">
                    {{ $t("title.common.distance") }}
                  </th>
                </tr>
              </thead>

              <tbody v-if="summary.top_avg_drive_list.length > 0">
                <template v-for="(item, index) in summary.top_avg_drive_list">
                  <tr :key="index">
                    <td class="col-auto d-lg-table-cell lh-1">
                      <strong class="text-black">{{ item.ranking }}</strong
                      ><br />
                    </td>
                    <td class="col-auto d-lg-table-cell text-start">
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :member_img="item.member_img"
                        :badge_img="item.badge_img"
                      />
                    </td>
                    <td class="col-auto d-lg-table-cell shop-name">
                      {{ item.shopname || "-" }}
                    </td>
                    <td class="col-auto d-sm-table-cell text-point-op">
                      {{ item.rank_field | toFixed | suffix("m") }}
                    </td>
                  </tr>
                </template>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="5" class="text-center">
                    {{ $t("text.common.no_data") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6">
            <h5 class="subtitle">
              {{ $t("title.common.avg_putts_distance") }}
            </h5>
            <h6 class="align-right">Complete leaders</h6>
            <table class="table board">
              <thead>
                <tr>
                  <th class="col-auto col-lg-1 d-lg-table-cell">
                    {{ $t("title.common.ranking") }}
                  </th>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.nickname") }}
                  </th>
                  <th class="col-auto d-none d-lg-table-cell">
                    {{ $t("title.common.favorite_location") }}
                  </th>
                  <th class="col-auto d-none d-sm-table-cell">
                    {{ $t("title.common.distance") }}
                  </th>
                </tr>
              </thead>

              <tbody v-if="summary.top_avg_putt_list.length > 0">
                <template v-for="(item, index) in summary.top_avg_putt_list">
                  <tr :key="index">
                    <td class="col-auto d-lg-table-cell lh-1">
                      <strong class="text-black">{{ item.ranking }}</strong
                      ><br />
                    </td>
                    <td class="col-auto d-lg-table-cell text-start">
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :member_img="item.member_img"
                        :badge_img="item.badge_img"
                      />
                    </td>
                    <td class="col-auto d-lg-table-cell shop-name">
                      {{ item.shopname || "-" }}
                    </td>
                    <td class="col-auto d-sm-table-cell text-point-op">
                      {{ item.rank_field | toFixed | suffix("m") }}
                    </td>
                  </tr>
                </template>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="5" class="text-center">
                    {{ $t("text.common.no_data") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row g-5 mb-4">
          <div class="col-6">
            <h5 class="subtitle">
              {{ $t("title.common.par_save_rate") }}
            </h5>
            <h6 class="align-right">Complete leaders</h6>
            <table class="table board">
              <thead>
                <tr>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.ranking") }}
                  </th>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.nickname") }}
                  </th>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.favorite_location") }}
                  </th>
                  <th class="col-auto d-sm-table-cell">%</th>
                </tr>
              </thead>

              <tbody v-if="summary.top_parsave_list.length > 0">
                <template v-for="(item, index) in summary.top_parsave_list">
                  <tr :key="index">
                    <td class="col-auto d-lg-table-cell lh-1">
                      <strong class="text-black">{{ item.ranking }}</strong
                      ><br />
                    </td>
                    <td class="col-auto d-lg-table-cell text-start">
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :member_img="item.member_img"
                        :badge_img="item.badge_img"
                      />
                    </td>
                    <td class="col-auto d-lg-table-cell shop-name">
                      {{ item.shopname || "-" }}
                    </td>
                    <td class="col-auto d-sm-table-cell text-point-op">
                      {{ item.rank_field | toFixed | suffix("%") }}
                    </td>
                  </tr>
                </template>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="5" class="text-center">
                    {{ $t("text.common.no_data") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6">
            <h5 class="subtitle">{{ $t("title.common.fareway_hit") }}</h5>
            <h6 class="align-right">Complete leaders</h6>
            <table class="table board">
              <thead>
                <tr>
                  <th class="col-auto col-lg-1 d-lg-table-cell">
                    {{ $t("title.common.ranking") }}
                  </th>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.nickname") }}
                  </th>
                  <th class="col-auto d-none d-lg-table-cell">
                    {{ $t("title.common.favorite_location") }}
                  </th>
                  <th class="col-auto d-none d-sm-table-cell">%</th>
                </tr>
              </thead>

              <tbody v-if="summary.top_fairway_list.length > 0">
                <template v-for="(item, index) in summary.top_fairway_list">
                  <tr :key="index">
                    <td class="col-auto d-lg-table-cell lh-1">
                      <strong class="text-black">{{ item.ranking }}</strong
                      ><br />
                    </td>
                    <td class="col-auto d-lg-table-cell text-start">
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :member_img="item.member_img"
                        :badge_img="item.badge_img"
                      />
                    </td>
                    <td class="col-auto d-lg-table-cell shop-name">
                      {{ item.shopname || "-" }}
                    </td>
                    <td class="col-auto d-sm-table-cell text-point-op">
                      {{ item.rank_field | toFixed | suffix("%") }}
                    </td>
                  </tr>
                </template>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="5" class="text-center">
                    {{ $t("text.common.no_data") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";

import ax from "@/api/halloffame";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "StatsSummary",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setYear(new Date().getFullYear())).format(
            "YYYY"
          ),
      },

      summary: {
        top_avg_score_list: [],
        top_greens_list: [],
        top_avg_drive_list: [],
        top_avg_putt_list: [],
        top_parsave_list: [],
        top_fairway_list: [],
      },
      view_hide: null,
    };
  },
  methods: {
    prev() {
      this.searchData.searchdate--;
    },
    next() {
      this.searchData.searchdate++;
    },
    get_stats_summary() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_stats_summary(this.searchData, (flag, data) => {
        this.summary = data.summary;

        if (!flag) alert(data.message);
      });
    },
    onSubmit(event) {
      event.preventDefault();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
  },
  created() {
    this.get_stats_summary();
  },
  watch: {
    "searchData.searchdate": function () {
      this.get_stats_summary();
    },
  },
  mixins: [myMixin],
};
</script>
