<template>
  <div>
    <location />
    <div class="page">
      <div class="wrap-1200">
        <div
          class="d-flex justify-content-center align-items-center mt-3 mt-lg-4 mb-2"
        >
          <button class="btn p-0" @click="prev()">
            <i class="material-icons fs-1">keyboard_arrow_left</i>
          </button>
          <h5 class="mx-2 text-center">
            {{ searchData.searchdate }} {{ $t("text.common.season") }}
          </h5>
          <button class="btn p-0" @click="next()">
            <i class="material-icons fs-1">keyboard_arrow_right</i>
          </button>
        </div>
        <b-form
          @submit="onSubmit"
          class="filter-group row row-cols-2 row-cols-sm-auto g-2 justify-content-end mt-4"
        >
          <div class="col">
            <select aria-label="" v-model="searchData.pagesize">
              <option value="10" selected>
                {{ $t("filter.common.sort_by_10") }}
              </option>
              <option value="50">{{ $t("filter.common.sort_by_50") }}</option>
              <option value="100">
                {{ $t("filter.common.sort_by_100") }}
              </option>
            </select>
          </div>

          <div class="col">
            <select aria-label="Select one" v-model="searchData.searchtype">
              <option value="nickname">
                {{ $t("filter.common.nickname") }}
              </option>
            </select>
          </div>
          <div class="col-12">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                name="searchname"
                v-model="searchData.searchname"
              />
              <button
                class="btn btn-primary btn-md icon-search"
                type="submit"
              ></button>
            </div>
          </div>
        </b-form>
        <div class="row mb-4">
          <div class="col-12">
            <table class="table board">
              <thead>
                <tr>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.ranking") }}
                  </th>
                  <th class="col-auto d-lg-table-cell">
                    {{ $t("title.common.nickname") }}
                  </th>
                  <th class="col-auto d-none d-lg-table-cell">
                    {{ $t("title.common.favorite_location") }}
                  </th>
                  <th
                    class="col-auto d-sm-table-cell more"
                    @click="_sort('avg_score')"
                  >
                    <template v-if="searchData.rank_field != 'avg_score'">
                      <i class="material-icons">expand_all</i
                      >{{ $t("title.common.avg_score") }}
                    </template>
                    <template v-else>
                      <i class="material-icons" v-if="searchData.sort == 'DESC'"
                        >arrow_upward_alt</i
                      >
                      <i class="material-icons" v-else>arrow_downward_alt</i>
                      {{ $t("title.common.avg_score") }}
                    </template>
                  </th>
                  <th
                    class="col-auto d-sm-table-cell more"
                    @click="_sort('greens')"
                  >
                    <template v-if="searchData.rank_field != 'greens'">
                      <i class="material-icons">expand_all</i
                      >{{ $t("title.common.gir") }}
                    </template>
                    <template v-else>
                      <i class="material-icons" v-if="searchData.sort == 'DESC'"
                        >arrow_upward_alt</i
                      >
                      <i class="material-icons" v-else>arrow_downward_alt</i
                      >{{ $t("title.common.gir") }}
                    </template>
                  </th>
                  <th
                    class="col-auto d-sm-table-cell more"
                    @click="_sort('avg_drive')"
                  >
                    <template v-if="searchData.rank_field != 'avg_drive'">
                      <i class="material-icons">expand_all</i
                      >{{ $t("title.common.avg_distance") }}
                    </template>
                    <template v-else>
                      <i class="material-icons" v-if="searchData.sort == 'DESC'"
                        >arrow_upward_alt</i
                      >
                      <i class="material-icons" v-else>arrow_downward_alt</i>
                      {{ $t("title.common.avg_distance") }}
                    </template>
                  </th>
                  <th
                    class="col-auto d-sm-table-cell more"
                    @click="_sort('avg_putt')"
                  >
                    <template v-if="searchData.rank_field != 'avg_putt'">
                      <i class="material-icons">expand_all</i
                      >{{ $t("title.common.avg_putts_distance") }}
                    </template>
                    <template v-else>
                      <i class="material-icons" v-if="searchData.sort == 'DESC'"
                        >arrow_upward_alt</i
                      >
                      <i class="material-icons" v-else>arrow_downward_alt</i>
                      {{ $t("title.common.avg_putts_distance") }}
                    </template>
                  </th>

                  <th
                    class="col-auto d-sm-table-cell more"
                    @click="_sort('parsave')"
                  >
                    <template v-if="searchData.rank_field != 'parsave'">
                      <i class="material-icons">expand_all</i
                      >{{ $t("title.common.par_save_rate") }}
                    </template>
                    <template v-else>
                      <i class="material-icons" v-if="searchData.sort == 'DESC'"
                        >arrow_upward_alt</i
                      >
                      <i class="material-icons" v-else>arrow_downward_alt</i>
                      {{ $t("title.common.par_save_rate") }}
                    </template>
                  </th>
                  <th
                    class="col-auto d-sm-table-cell more"
                    @click="_sort('fairway')"
                  >
                    <template v-if="searchData.rank_field != 'fairway'">
                      <i class="material-icons">expand_all</i
                      >{{ $t("title.common.fareway_hit") }}
                    </template>
                    <template v-else>
                      <i class="material-icons" v-if="searchData.sort == 'DESC'"
                        >arrow_upward_alt</i
                      >
                      <i class="material-icons" v-else>arrow_downward_alt</i>
                      {{ $t("title.common.fareway_hit") }}
                    </template>
                  </th>

                  <th class="th-arrow">&nbsp;</th>
                </tr>
              </thead>

              <tbody v-if="items.length > 0">
                <template v-for="(item, index) in items">
                  <tr :key="index">
                    <td class="col-auto d-lg-table-cell lh-1">
                      <strong class="text-black">{{
                        searchData.sort == "ASC"
                          ? index +
                            1 +
                            (searchData.pageno - 1) * searchData.pagesize
                          : items_.length -
                            (index +
                              (searchData.pageno - 1) * searchData.pagesize)
                      }}</strong
                      ><br />
                    </td>
                    <td class="col-auto d-lg-table-cell text-start">
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.player_pk"
                        :member_img="item.member_img"
                        :badge_img="item.badge_img"
                      />
                    </td>
                    <td class="col-auto d-lg-table-cell shop-name d-none">
                      {{ item.shopname || "-" }}
                    </td>
                    <td class="col-auto d-sm-table-cell text-point-op">
                      {{ item.avg_score | toFixed }}
                    </td>
                    <td class="col-auto d-sm-table-cell text-point-op">
                      {{ item.greens | toFixed | suffix("%") }}
                    </td>
                    <td class="col-auto d-sm-table-cell text-point-op">
                      {{ item.avg_drive | toFixed | suffix("m") }}
                    </td>
                    <td class="col-auto d-sm-table-cell text-point-op">
                      {{ item.avg_putt | toFixed | suffix("m") }}
                    </td>
                    <td class="col-auto d-sm-table-cell text-point-op">
                      {{ item.parsave | toFixed | suffix("%") }}
                    </td>
                    <td class="col-auto d-sm-table-cell text-point-op">
                      {{ item.fairway | toFixed | suffix("%") }}
                    </td>
                    <td class="td-arrow" @click="toggle_tr(index)">
                      <img
                        src="/img/arrow_none_collapsed.png"
                        :class="{ 'btn-toggle-arrow': view_hide === index }"
                      />
                    </td>
                  </tr>
                  <tr
                    class="d-lg-none"
                    :class="{ show: view_hide === index }"
                    :key="index + 'r'"
                    v-if="view_hide === index"
                  >
                    <td colspan="5">
                      <div class="toggle-content">
                        <div class="row">
                          <div class="col-6 col-sm-4">
                            {{ $t("title.common.favorite_location") }}
                          </div>
                          <div class="col-6 col-sm-8 shop-name">
                            {{ item.shopname || "-" }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-sm-4">
                            {{ $t("title.common.avg_score") }}
                          </div>
                          <div class="col-6 col-sm-8 text-point-op">
                            {{ item.avg_score | toFixed }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-sm-4">
                            {{ $t("title.common.gir") }}
                          </div>
                          <div class="col-6 col-sm-8 text-point-op">
                            {{ item.greens | toFixed | suffix("%") }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-sm-4">
                            {{ $t("title.common.avg_distance") }}
                          </div>
                          <div class="col-6 col-sm-8 text-point-op">
                            {{ item.avg_drive | toFixed | suffix("m") }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-sm-4">
                            {{ $t("title.common.avg_putts_distance") }}
                          </div>
                          <div class="col-6 col-sm-8 text-point-op">
                            {{ item.avg_putt | toFixed | suffix("m") }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-sm-4">
                            {{ $t("title.common.par_save_rate") }}
                          </div>
                          <div class="col-6 col-sm-8 text-point-op">
                            {{ item.parsave | toFixed | suffix("%") }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-sm-4">
                            {{ $t("title.common.fareway_hit") }}
                          </div>
                          <div class="col-6 col-sm-8 text-point-op">
                            {{ item.fairway | toFixed | suffix("%") }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="9" class="text-center">
                    {{ $t("text.common.no_data") }}
                  </td>
                </tr>
              </tbody>
            </table>
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="Math.ceil(items_.length / searchData.pagesize)"
              v-model="searchData.pageno"
              use-router
              aria-label="Page navigation"
              v-if="items_.length / searchData.pagesize > 1"
              first-class="prev"
              prev-class="prev"
              next-class="next"
              last-class="next"
              hide-ellipsis
              limit="10"
            >
              <template #first-text
                ><i class="material-icons"
                  >keyboard_double_arrow_left</i
                ></template
              >
              <template #prev-text
                ><i class="material-icons">navigate_before</i></template
              >
              <template #next-text
                ><i class="material-icons">navigate_next</i></template
              >
              <template #last-text
                ><i class="material-icons"
                  >keyboard_double_arrow_right</i
                ></template
              >
              <template #page="{ page, active }">
                <div v-if="active">{{ page }}</div>
                <div v-else>{{ page }}</div>
              </template>
            </b-pagination-nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";

import ax from "@/api/halloffame";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "Stats",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        rank_field:
          this.$route.query.rank_field ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .rank_field) ||
          "avg_score",
        sort:
          this.$route.query.sort ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name].sort) ||
          "ASC",

        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setYear(new Date().getFullYear())).format(
            "YYYY"
          ),
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "nickname",
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        pageno: this.$route.query.pageno || 1,

        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .pagesize) ||
          "10",
      },
      page_total: 1,
      total_cnt: 0,

      items: [],
      items_: [],
      shotstats: [],
      view_hide: null,
    };
  },
  methods: {
    prev() {
      this.searchData.searchdate--;
    },
    next() {
      this.searchData.searchdate++;
    },
    get_list() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_statslist(this.searchData, (flag, data) => {
        // this.items = data.list;

        // this.total_cnt = data.listcnt;
        // this.page_total = Math.ceil(data.listcnt / this.searchData.pagesize);
        this.shotstats = data.list;

        this.setInit();
        if (!flag) alert(data.message);
      });
    },

    setInit() {
      if (this.searchData.searchname)
        this.items_ = this.shotstats.filter((v) =>
          v[this.searchData.searchtype]
            .toLowerCase()
            .includes(this.searchData.searchname.toLowerCase())
        );
      else this.items_ = this.shotstats;
      // console.log("this.items_", this.items_);
      if (
        (this.searchData.sort == "ASC" &&
          this.searchData.rank_field == "avg_score") ||
        (this.searchData.sort == "DESC" &&
          this.searchData.rank_field != "avg_score")
      ) {
        this.items_.sort((a, b) => {
          if (
            parseFloat(a[this.searchData.rank_field]) >
            parseFloat(b[this.searchData.rank_field])
          ) {
            return 1;
          }
          if (
            parseFloat(a[this.searchData.rank_field]) <
            parseFloat(b[this.searchData.rank_field])
          ) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      } else {
        this.items_.sort((a, b) => {
          if (
            parseFloat(a[this.searchData.rank_field]) <
            parseFloat(b[this.searchData.rank_field])
          ) {
            return 1;
          }
          if (
            parseFloat(a[this.searchData.rank_field]) >
            parseFloat(b[this.searchData.rank_field])
          ) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
      this.items = this.items_.slice(
        (this.searchData.pageno - 1) * this.searchData.pagesize,
        this.searchData.pageno * this.searchData.pagesize
      );
    },
    onSubmit(event) {
      event.preventDefault();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    _sort(rank_field) {
      this.searchData.rank_field = rank_field;
      this.searchData.sort = this.searchData.sort === "DESC" ? "ASC" : "DESC";
    },
  },
  created() {
    this.get_list();
  },

  watch: {
    "searchData.searchdate": function () {
      this.searchData.pageno = 1;
      this.get_list();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.setInit();
    },
    "searchData.sort": function () {
      this.searchData.pageno = 1;
      this.setInit();
    },
    "searchData.pageno": function () {
      this.setInit();
    },
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.setInit();
    },
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.setInit();
    },
    "searchData.rank_field": function () {
      this.searchData.pageno = 1;
      this.setInit();
    },
  },
  mixins: [myMixin],
};
</script>
